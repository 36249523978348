html {
  scroll-behavior: smooth;
}

@media (min-width: 320px) {
  .first-swiper .swiper-wrapper {
    padding: 0 12px;
  }

  .first-swiper .swiper-slide {
    max-width: 254px;
    min-height: 254px;
    max-height: 254px;
  }
}

@media (min-width: 616px) {
  .main-image {
    height: 466px;
    top: -36px;
    border-radius: 48px;
  }

  .coupon-cashback-container > div {
    height: 648px;
  }

  .coupon-cashback-container > div > img {
    max-width: 648px;
    max-height: 648px;
  }

  .third-swiper .swiper-slide {
    max-width: 304px;
  }
}

@media (min-width: 945px) {
  .main-image {
    height: 648px;
    top: -54px;
    border-radius: 48px;
  }

  .first-swiper .swiper-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    padding: 0;
  }

  .first-swiper .swiper-slide {
    max-width: 256px;
    min-height: 320px;
    max-height: 320px;
  }

  .second-swiper .swiper-wrapper {
    display: flex;
    flex-direction: column;
  }

  .third-swiper .swiper-slide {
    max-width: 304px;
  }
}

.first-swiper .swiper-slide:first-child + .swiper-slide {
  background-color: #ffedf1;
}

.first-swiper .swiper-slide:last-child {
  background-color: #e5f9f2;
}

.coupon-cashback-container {
  background-color: #000;
}

.coupon-cashback-container > div img {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.carousel > section {
  height: fit-content;
}
